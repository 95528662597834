import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const CatalogItemPage = () => (
	<Layout>
		<Seo title="Silent" />

		<h2>Silent</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST05 silent front.png"
			alt="Jesse Rivest - Silent - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Prerelease note</h3>
		<p>
			To be released on April 1, 2021.
		</p>

		<h3>Words from Jesse</h3>
		<p>
			When I travelled to Wellington, New Zealand, in 2006, my friend Kyle te Kiwi picked me up and showed me around for a couple days.
			A true friend, he arranged for me to stay in Wellington at his friend Anjuli's place, while he returned to his home in Motueka.
			Anjuli was cool and kind, and I was able to crash with her for a few months while I got on my feet.
			I would sit in the lounge some nights practicing or writing; one such night, as I recalled some incidents from my time prior in Australia, I came up with Silent.
			It's two road stories linked together in one song.
		</p><p>
			In those early days in Wellington (I ended up living in the city for seven years!), I tried this song out on various audiences... but I think I never felt fully comfortable with it.
			Was I even comfortable with myself?
			Was it a serious song? Or a silly constructed idea?
			Regardless, I wore it loud and clear for a good while, because it was a new song and that's what I was doing—writing songs and performing them.
			I played it for my musical friend, Vorn, who commented that it felt like a hymn. I didn't disagree—it did! A waltzing hymn.
			I think I tried it out at the folk club, but I can't remember how they received it.
			I imagine they didn't know what to make of it, because I sure didn't.
			I remember playing it at a house concert in Christchurch and my friend and musical colleague, James, was smiling.
			I had an opportunity in 2007 to perform live on Radio New Zealand's NZ Live; I brought my friend and musical colleague, Rachael, to accompany me with her cello.
			We played Silent, but I can't imagine what Jim Mora (the show presenter) thought of it; it was the last song of the interview, so there wasn't much time for me to read his reaction. He said, "Excellent," after what I'm sure must have been a nearly seven-minute rendition!
			Honestly, if I wasn't feeling comfortable with the song, then I would have likely projected a reaction on to him. In fact, I probably did do that.
			I know my friend (another musical colleague) Fiona liked it, and I had hoped to one day have her play violin on the song.
			Searching my memory, it seems the song sort of drifted into silence. It was never produced or recorded in a studio, and it couldn't be played at gigs that were expecting upbeat music.
			However, it never fully quieted itself.
		</p><p>
			Fast forward to these days.
			I'm in Brazil and I still think of the song and those times.
			I was looking for some tracks to hone my production skills before working on a more current set of songs, and Silent put its hand up.
			Silent no longer!
			Here is the song nearly as I originally wrote it.
			I changed one word—men became folk—and I changed the key of the song partway through recording it so I could achieve a different timbre with my voice. The song has become shorter over the years—it started out much too long—without sacrificing any melody, lyrics, or story.
			I'm very fond of my past time in Australia and New Zealand, and I'm fond of this song, too. Here, you have it now.
		</p>

		<h3>Track listing</h3>
		<ol>
			<li>Silent (5:21) <br /> CA-6P8-21-00001 - © Jesse Rivest 2007</li>
		</ol>
		<p>
			This recording: catalog number JRIVEST05, © Jesse Rivest 2021, All Rights Reserved<br />
			UPC/EAN: 0195999467122<br />
			Released: April 1, 2021
		</p>

		<h3>Credits</h3>
		<p>
			Produced, recorded, mixed, and mastered by Jesse Rivest at his Little Bakery Studio in Brasilia, DF, Brazil.<br />
			Song written and sung (main and backing vocals) by Jesse Rivest.<br />
			Electric guitar and acoustic guitar played by Jesse Rivest.<br />
			Programming of drums, synths, and bass synth by Jesse Rivest.<br />
			Album cover artwork and layout by Jesse Rivest.<br />
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to Jane Lino for being supportive, always.<br />
			Thanks to Gustavo Halfeld for lending his ears.
		</p>

		<h3>Extras</h3>
		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST05 silent back.png"
			alt="Jesse Rivest - Silent - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3 className="lyrics">Lyrics</h3>
		<div>
			<h4 className="lyrics__title">
				Silent</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2007
			</h5>
			<p>
				The Nullarbor highway is long and bare<br />
				It can lead good folks to misthinking<br />
				We drove it in shifts to remain aware<br />
				And we crossed off the days as they passed
			</p>
			<p>
				We stopped at a roadhouse to rest and repair<br />
				Oh, the dingos were looking wistful<br />
				We checked all the tires and filled them with air<br />
				And we topped up the diesel and oil
			</p>
			<p>
				Despite all my warnings that the coolant was too hot<br />
				He turned the cap and burnt his face off!<br />
				Oh, boy
			</p>
			<p>
				We were silent for the rest of the drive<br />
				We were silent through the stretched countryside<br />
				We were each in our own places; we were both in the same space<br />
				We were silent for a while
			</p>
			<p>
				The road out of Warroora was slow at night<br />
				Oh, the kangaroos were jumping<br />
				We drove cautiously with our headlights bright<br />
				And we dodged every creature we saw
			</p>
			<p>
				Except for the owl that gave us a fright<br />
				Oh, it lay on the road behind us<br />
				We got out of the truck to see how we might<br />
				Put it out of its twitching misery
			</p>
			<p>
				While two of us men looked for an axe or a shovel<br />
				The girl picked it up and ripped its head off!<br />
				Oh, boy
			</p>
			<p>
				We were silent for the rest of the drive<br />
				We were silent through the dark countryside<br />
				We were each in our own places; we were all in the same space<br />
				We were silent<br />
				We were silent<br />
				We were silent for a while
			</p>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
